$bordersize: 1px;
$bordermargin: 25px;
$bordermarginDouble: $bordermargin*2;

// Mobile margins

$bordersize-sm: 1px;
$bordermargin-sm: 5vw;
$bordermarginDouble-sm: $bordermargin-sm*2;

$marginSides:1.7vw;
$marginSides-Double: ($marginSides * 2) ;

.border {
  position: fixed;
}

.border--topLeft {
  top:0;
  left: 0;
}

.border--bottomRight {
  bottom:0;
  right: 0;
}

// #border--wrapper {
//   position: fixed;
//   height: calc(100vh - (#{$bordermargin} * 2));
//   width: calc(100% - (#{$bordermargin} * 2));
//   border: 1px solid $border;
//   margin: 0 $bordermargin;
// }

//top
#border1 {
  width: calc(100% - (#{$bordermargin} * 2));
  margin-left: #{$bordermargin};
  display: block;
  // width: 100%;
  height: $bordermargin;
  background-color: $white;
  border-bottom: $bordersize solid $border;
  z-index: 200;
}

//right
#border2 {
  width: $bordermargin;
  margin-bottom: #{$bordermargin};
  height: calc(100vh - (#{$bordermargin} * 2));
  // height: 100vh;
  background-color: $white;
  border-left: $bordersize solid $border;
  z-index: 200;
}

// bottom
#border3 {
  height: $bordermargin;
  background-color: $white;
  width: calc(100% - (#{$bordermargin} * 2));
  margin-right: #{$bordermargin};
  // width: 100%;
  border-top: $bordersize solid $border;
  z-index: 200;
}

// left
#border4 {
  width: $bordermargin;
  margin-top: #{$bordermargin};
  height: calc(100vh - (#{$bordermargin} * 2));
  // height: 100vh;
  background-color: $white;
  border-right: $bordersize solid $border;
  z-index: 200;
}

#border5 {

  background-color: $white;
  // border-bottom: $bordersize solid $border;
  width: 100%;
  height: $bordermargin - 1;
  z-index: 210;
}

#border6 {

  background-color: $white;
  // border-left: $bordersize solid $border;
  width: $bordermargin - 1;
  height: 100vh;
  z-index: 210;
}

#border7 {

  background-color: $white;
  // border-top: $bordersize solid $border;
  width: 100%;
  height: $bordermargin - 1;
  z-index: 210;
}

#border8 {

  background-color: $white;
  // border-right: $bordersize solid $border;
  width: $bordermargin - 1;
  height: 100vh;
  z-index: 210;
}

@include bp(mobile){

  // #border1 {
  //   height: $bordermargin-sm;
  //   background-color: $white;
  // }
  //
  // #border2 {
  //   width: $bordermargin-sm;
  //   background-color: $white;
  // }
  //
  // #border3 {
  //   height: $bordermargin-sm;
  //   background-color: $white;
  // }
  //
  // #border4 {
  //   width: $bordermargin-sm;
  //   background-color: $white;
  // }
  //
  // #border5 {
  //   height: $bordermargin-sm;
  // }
  //
  // #border6 {
  //   width: $bordermargin-sm;
  // }
  //
  // #border7 {
  //   height: $bordermargin-sm;
  // }
  //
  // #border8 {
  //   width: $bordermargin-sm;
  // }
  #border1 {
    width: calc(100% - (#{$bordermargin-sm} * 2));
    margin-left: #{$bordermargin-sm};
    height: $bordermargin-sm;
  }

  //right
  #border2 {
    width: $bordermargin-sm;
    margin-bottom: #{$bordermargin-sm};
    height: calc(100vh - (#{$bordermargin-sm} * 2));
  }

  // bottom
  #border3 {
    height: $bordermargin-sm;
    width: calc(100% - (#{$bordermargin-sm} * 2));
    margin-right: #{$bordermargin-sm};
  }

  // left
  #border4 {
    width: $bordermargin-sm;
    margin-top: #{$bordermargin-sm};
    height: calc(100vh - (#{$bordermargin-sm} * 2));
  }

  #border5 {
    height: $bordermargin-sm;
  }

  #border6 {
    width: $bordermargin-sm;
  }

  #border7 {
    height: $bordermargin-sm;
  }

  #border8 {
    width: $bordermargin-sm;
  }

}
