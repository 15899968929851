#profil {
  #jsMenu {
    border-bottom: 0;
  }
}

@include bp(ipadV){
  #profil {
    .mainSection--wrapper {
      section:first-of-type {
        min-height: initial;
      }
    }
  }
}

@include bp(mobile){
  #profil {
    .mainSection--wrapper {
      section:first-of-type {
        min-height: initial;
      }
    }
  }
}
