@font-face{
  font-family:"Avenir LT W01_35 Light1475496";
  src:url("../assets/fonts/edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix");
  src:url("../assets/fonts/edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix") format("eot"),url("../assets/fonts/0078f486-8e52-42c0-ad81-3c8d3d43f48e.woff2") format("woff2"),url("../assets/fonts/908c4810-64db-4b46-bb8e-823eb41f68c0.woff") format("woff"),url("../assets/fonts/4577388c-510f-4366-addb-8b663bcc762a.ttf") format("truetype"),url("../assets/fonts/b0268c31-e450-4159-bfea-e0d20e2b5c0c.svg#b0268c31-e450-4159-bfea-e0d20e2b5c0c") format("svg");
}
@font-face{
  font-family:"Avenir LT W01_45 Book1475508";
  src:url("../assets/fonts/710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix");
  src:url("../assets/fonts/710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix") format("eot"),url("../assets/fonts/065a6b14-b2cc-446e-9428-271c570df0d9.woff2") format("woff2"),url("../assets/fonts/65d75eb0-2601-4da5-a9a4-9ee67a470a59.woff") format("woff"),url("../assets/fonts/c70e90bc-3c94-41dc-bf14-caa727c76301.ttf") format("truetype"),url("../assets/fonts/0979215b-3a1b-4356-9c76-e90fa4551f1d.svg#0979215b-3a1b-4356-9c76-e90fa4551f1d") format("svg");
}
@font-face{
  font-family:"Avenir LT W01_85 Heavy1475544";
  src:url("../assets/fonts/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix");
  src:url("../assets/fonts/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix") format("eot"),url("../assets/fonts/d513e15e-8f35-4129-ad05-481815e52625.woff2") format("woff2"),url("../assets/fonts/61bd362e-7162-46bd-b67e-28f366c4afbe.woff") format("woff"),url("../assets/fonts/ccd17c6b-e7ed-4b73-b0d2-76712a4ef46b.ttf") format("truetype"),url("../assets/fonts/20577853-40a7-4ada-a3fb-dd6e9392f401.svg#20577853-40a7-4ada-a3fb-dd6e9392f401") format("svg");
}

$fAlight: 'Avenir LT W01_35 Light1475496';
$fAbook: 'Avenir LT W01_45 Book1475508';
$fAheavy: 'Avenir LT W01_85 Heavy1475544';


@font-face{
  font-family:"Space Mono Reg";
  src:url("../assets/fonts/SpaceMono-Regular.ttf") format("truetype");
}
@font-face{
  font-family:"Space Mono Bold";
  src:url("../assets/fonts/SpaceMono-Bold.ttf") format("truetype");
}

$fSMreg: 'Space Mono Reg';
$fSMbold: 'Space Mono Bold';


/// least-squares-fit
/// Calculate the least square fit linear regression of provided values
/// @param {map} $map - A SASS map of viewport width and size value combinations
/// @return Linear equation as a calc() function
/// @example
///   font-size: least-squares-fit((576: 24, 768: 24, 992: 34));
@function least-squares-fit($map) {

  // Get the number of provided breakpoints
  $length: length(map-keys($map));

  // Error if the number of breakpoints is < 2
  @if ($length < 2) {
    @error "leastSquaresFit() $map must be at least 2 values";
  }

  // Calculate the Means
  $resTotal: 0;
  $valueTotal: 0;
  @each $res, $value in $map {
    $resTotal: $resTotal + $res;
    $valueTotal: $valueTotal + $value;
  }
  $resMean: $resTotal/$length;
  $valueMean: $valueTotal/$length;

  // Calculate some other stuff
  $multipliedDiff: 0;
  $squaredDiff: 0;
  @each $res, $value in $map {

    // Differences from means
    $resDiff: $res - $resMean;
    $valueDiff: $value - $valueMean;

    // Sum of multiplied differences
    $multipliedDiff: $multipliedDiff + ($resDiff * $valueDiff);

    // Sum of squared resolution differences
    $squaredDiff: $squaredDiff + ($resDiff * $resDiff);
  }

  // Calculate the Slope
  $m: $multipliedDiff / $squaredDiff;

  // Calculate the Y-Intercept
  $b: $valueMean - ($m * $resMean);

  // Return the CSS calc equation
  @return calc(#{$m*100}vw + #{$b}px);

}

h1 {
  $map: (300: 30, 1280: 36, 1500: 45);
  font-size: least-squares-fit($map);
}
#list--left li.active a {
  $map: (300: 26, 1280: 30, 1500: 40);
  font-size: least-squares-fit($map);
}

h2, #menuList--wrapper ul a, #list--left li a  {
  transition: 1s all;
  $map: (300: 24, 1280: 24, 1800: 30);
  font-size: least-squares-fit($map);
}

h3, #logo--wrapper div  {
  $map: (300: 12, 1280: 13, 1500: 17);
  font-size: least-squares-fit($map);
}

h4 {
  $map: (300: 12, 1280: 13, 1500: 17);
  font-size: least-squares-fit($map);
}

p, #team #list--left li .contact_link a {
  $map: (300: 12, 1280: 13, 1500: 15);
  font-size: least-squares-fit($map);
}

.asterix--content {
  $map: (300: 12, 1280: 13, 1500: 13);
  font-size: least-squares-fit($map);
}

.contact_info {
  font-family: $fSMreg;
  letter-spacing: 0.1em;
}

.contact_link {
  font-family: $fSMreg;
}

@include bp(mobile) {
  p, h3, h4 {
    font-size: 14px;
  }
}
