$text: #161615;
$border: #1D1D1B;
$red: red;
$white: white;


@mixin color_hover($baseColor) {
    color: $baseColor;
    &:hover  {
    	color: darken($baseColor, 13%);
    }
}
