.pfeil {
  z-index: 999;
}

#referenzen {
  .right--wrapper {
    padding: 0;
    overflow: hidden;
    > div {
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
  .left--wrapper {
    //border-right: $bordersize solid $border;
    @include bp(mobile) {
      min-height: initial;
      //border-bottom: 1px solid black;
    }
    @include bp(ipadV) {
      min-height: initial;
      //border-bottom: 1px solid black;
    }
  }
  .menu--container {
    //outline: blue solid 1px;
  }
}

.project--wrapper {
  border-bottom: $bordersize solid $border;
  &.inactive {
    display: none;
  }
}

.project {
  padding: $marginSides-Double/2 0;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  transition: 1s padding;
  .project--title, .project--arrow {
    margin-right: $marginSides;
  }
  .project--title, .project--descr {
    flex:2;
  }
  .project--descr {
    flex:3;
    max-width: 20vw;
    margin-right: 3vw;
    opacity: 1;
    transition: 0.5s opacity;
  }
  .project--year {
    text-align: right;
    margin-right: $marginSides;
    flex:0;
    display: table;
  }
  .mobile--wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-right: 3.4vw;
    padding: 1rem;
    h3 {
      line-height: inherit;
    }
  }
  .project--arrow {
    height: 25px;
    cursor: pointer;
    transform: rotate(180deg);
    transition: 0.75s transform;
    width: 20px;
    svg {
      width: 100%;
      height: 100%;
    }
    @include bp(mobile) {

    }
  }
  .project--details {
    flex: 1 100%;
    margin: $marginSides $marginSides $marginSides $marginSides-Double ;
    @include bp(mobile) {
      margin: 0;
      padding: 1rem;
    }
    display: none;
    > div {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: space-between;
      margin-right: 4.2vw;
      > div {
        flex: 1;
        ul  {
          margin-top: $marginSides ;
          min-width: 300px;
          width: 25vw;
          @include bp(mobile) {
            min-width: inherit;
            width: auto;
          }
          li {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            margin: 0.25rem;
            @include bp(mobile) {
              display: block;
            }

            p {
              &:first-of-type {
                flex:2;
                font-family: $fSMreg;
                @include bp(mobile) {
                  margin-top: 1rem;
                  margin-bottom: 0.25rem;
                }
              }
              &:last-of-type {
                flex:2;
              }
            }
          }
        }
        &:last-of-type {
          width: 100%;
          max-width: 17vw;
          display: flex;
          flex-direction: column;

        }
      }
    }
  }
}


.projectHover--wrapper{
  display: none;
  position: relative;
  &.hoverImg {
    display: block;
    position: absolute;
    right: -100%;
    cursor: none;
    @include bp(mobile) {
      display: none;
    }
    @include bp(ipadV) {
      display: none;
    }
  }
  img {
    width: 200px;
    @include object-fit(cover);
  }
}

.project--wrapper.active {
  .project {
    padding: calc((#{$headerheight} - 0.5px) / 3) 0;
    > p.project--descr {
      opacity: 0;
    }
    @include bp(mobile) {
      padding: $bordermarginDouble-sm 0;
    }
  }
  .projectHover--wrapper{
    display: none;
    &.hover {
      display: none;
      cursor: auto;
    }
  }
  .project--arrow {
    transform: rotate(0deg);
  }
}

#filters--wrapper {
  .project {
    border-top: $bordersize solid $border;
    border-bottom: $bordersize solid $border;
  }
}

#filters {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  .filter--answers {
    display: none;
    &.active {
      display: block;
    }
  }
  li {
    padding: 0.25em 0;
  }
  aside {
    margin-left: $marginSides-Double;
    padding: $marginSides 0;
    flex: 1 30%;
    border-right: $bordersize solid $border;
    li {
      font-family:$fSMreg;
      padding: 0.25rem 0;
      &.active {
        font-family:$fSMbold;
      }
      &:hover {
        font-family:$fSMbold;
      }
    }
  }
  > div {
    margin-left: calc(#{$marginSides-Double} * 1.5);
    padding: $marginSides 0;
    flex: 1 70%;
    li {
      font-family: $fAlight;
      &.active {
        font-family:$fAheavy;
      }
      &:hover {
        font-family:$fAheavy;
      }
    }
  }
}

.asterix--container {
  position: relative;
  .asterix {
    cursor: pointer;
  }
  .asterix--content {
    position: absolute;
    bottom: 0;
    padding: 1rem;
    left: 38.5%;
    display: none;
    width: 140%;
    background-color: white;
    border: 1px solid;
    z-index: 100;
    &.active {
      display: block;
    }
  }
}

@include bp(desktop) {
  .project {
    .mobile--wrapper {
      display: none;
    }
  }
}

@include bp(ipadH) {
  .project {
    .mobile--wrapper {
      display: none;
    }
  }
  .asterix--container {
    .asterix--content {
      width: 200%;
    }
  }
}

@include bp(ipadV) {
  .project {
    .mobile--wrapper {
      display: none;
    }
  }
  .project .project--descr {
    max-width: initial;
  }
  #filters {
    >div {
      margin-left: $marginSides-Double;
      padding: $marginSides 0;
    }
  }
  .asterix--container {
    .asterix--content {
      width: 300%;
    }
  }
}

@include bp(mobile) {
  .project {
    //margin-left: 1.7vw;
    padding: $bordermargin-sm 0;
    .project--descr, .project--year {
      display: none;
    }
    .project--details {
      > div {
        display: initial;
        margin-right: initial;
        > div {
          display: block;
          width: 100%;
          &:last-of-type {
            width: 100%;
            height: 150px;
            max-width: initial;
            margin-top: 3.4vw;
            display: inline-block;
          }
        }
      }
    }
    .asterix--container {
      .asterix {
        display: none;
      }
    }
  }
  #filters {
    >div {
      margin-left: $marginSides-Double;
      padding: $marginSides 0;
    }
  }
}
