section {
  z-index: 6;
  position: relative;
  //background-color: $white;
}

.mainSection--wrapper {
  min-height: calc(100vh - #{$headerheight} - #{$bordermargin}*2 - 1px);
  margin: 0 $bordermargin;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-start;
  section {
    &:first-of-type {
      margin-right: 0;
      top: 0;
      width: calc(#{$widthLeft});
      min-height: 100vh;
    }
    &:last-of-type {
      margin-left: 0;
      width: calc(#{$widthRight});
      border-left: $bordersize solid $border;
      min-height: 100vh;
    }
  }
}

//.left--wrapper {//to replace when sure it doesn't cause any bugs
  .menu--container > div {
    &:first-of-type {
      height: calc(2 * #{$headerheight});
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      > div {
        flex:0 50%;
        border-bottom: $bordersize solid $border;
        &:first-of-type {
          border-right: $bordersize solid $border;
        }
        &:last-of-type {
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    &:last-of-type {
      //min-height: calc(100vh - (3 * #{$headerheight}) -  (2 * #{$bordermargin}));
      transition: 500ms min-height;
      h1 {
        padding: 5vh 0 0;
      }
      h1, h2, h4 {
        margin-left: $marginSides-Double;
      }
      h3 {
        margin-left:calc(#{$marginSides-Double} * 1.5);
        margin-right:calc(#{$marginSides-Double} * 1.5);
        margin-bottom:calc(#{$marginSides-Double} * 1.5);
        margin-top:calc(#{$marginSides-Double} * 1.25);
        text-align: justify;
      }
    }
  }
//}

#menuList--wrapper {
 li {
   &:first-of-type {
     border-top: none;
   }
 }
}

.menu--container {
  border-top: $bordersize solid $border;
  border-bottom: $bordersize solid $border;
  margin-top: -1px !important;
}
#homepage, #kontakt, #team {
  .menu--container {
    border-bottom: none;
  }
}

.mainSection--wrapper > section {
  .list{
    li {
      &:first-of-type {
        border-top: $bordersize solid $border;
      }
      border-bottom: $bordersize solid $border;
      transition: 0.25s letter-spacing;
      a {
        padding: $marginSides-Double/2 $marginSides-Double;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: $fSMreg;
        span {
          transition: 0.75s transform;
          transform: rotate(180deg);
          height: 25px;
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          svg {
            height: 100%;
          }
        }
      }
      > .details {
        display: none;
        h4 {
          margin-top: $marginSides-Double/8;
          margin-bottom: $marginSides-Double/2;
          //margin-top: $marginSides-Double;
          //margin-bottom: $marginSides-Double;
        }
      }
      &.active {
        > a {
          min-height: inherit;
          padding: 5vh $marginSides-Double 0;
          span {
            transform: rotate(0deg);
          }
        }
        > .details {
          display: block;
        }
      }
    }
  }
}

.right--wrapper {
  > div {
    padding: 0 $bordermargin;
    box-sizing: border-box;
    background-color: $white;
    > img {
      padding-bottom: $bordermargin;
      //height: calc(100vh - (#{$bordermarginDouble} * 2) + 1px);
      width: 100%;
      //@include object-fit(cover);

    }
    &:first-of-type {
      margin-top: $bordermargin;
    }
  }
}

.right--wrapper {
  h2 {
    height: calc(2 * #{$headerheight} - 1px);
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    border-bottom: $bordersize solid $border;
  }
  h2, h3 {
    padding-left: $marginSides-Double/1.5;
  }
}

#rightWrapper--mobile > div {
  &:first-of-type {
    height: calc(2 * #{$headerheight});
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    > div {
      flex:0 50%;
      border-bottom: $bordersize solid $border;
      &:first-of-type {
        border-right: $bordersize solid $border;
      }
      &:last-of-type {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

#homepage, #referenzen, #kontakt {
  #scrollToTop {
    display: none;
  }
}

#scrollToTop {
  position: fixed;
  bottom: #{$bordermargin};
  right: #{$bordermargin};
  width: 60px;
  height: 60px;
  //padding: 1em;
  background-color: rgba(255, 255, 255, 1);
  border-top: $bordersize solid $border;
  border-left: $bordersize solid $border;
  z-index: 100;
  opacity: 0;
  transition: opacity 750ms ease;
  cursor: pointer;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-top: 0.2em;
    margin-left: - ($bordersize * 2);
  }
  &.active {
    opacity: 1;
  }
}


@include bp(mobile){
  .mainSection--wrapper {
    display: block;
    margin: 0 $bordermargin-sm;
    section {
      &:first-of-type {
        width: 100%;
      }
      &:last-of-type {
        width: 100%;
      }
    }
  }
  .right--wrapper > div{
      padding: 0;
  }
  #hrefDesktop {
    display: none;
  }
  #scrollToTop {
    bottom: #{$bordermargin-sm};
    right: #{$bordermargin-sm};
  }
}



@include bp(ipadV){
  .mainSection--wrapper {
    display: block;
    section {
      &:first-of-type {
        width: 100%;
      }
      &:last-of-type {
        width: 100%;
      }
    }
  }

  .right--wrapper > div{
      padding: 0;
  }

  #hrefDesktop {
    display: none;
  }
}

@include bp(ipadH){
}

@include bp(desktop){
}
