#kontakt {
  .left--wrapper, .right--wrapper {
    min-height: calc(100vh - #{$headerheight} - #{$bordermargin} * 2 - 1px);
    @include bp(mobile) {
      min-height: 0;
    }
    @include bp(ipadV) {
      min-height: 0;
    }
  }
  .left--wrapper {
    .list--container {
      h1 {
        padding: 5vh 0 0;
        margin-left: $marginSides-Double;
        @include bp(mobile) {
          margin-left:calc(#{$marginSides-Double} * 1.5);
        }
        @include bp(ipadV) {
          margin-left:calc(#{$marginSides-Double} * 1.5);
        }

      }
    }
    #kontakt--wrapper {
      margin-top:calc(#{$marginSides-Double} * 1.25);
      margin-left:calc(#{$marginSides-Double} * 1.5);
      margin-right:calc(#{$marginSides-Double} * 1.5);
      h4 {
        margin-left: 0;
        margin-bottom: 0;
        letter-spacing: 0.1em;
      }
      .name {
        font-size: 1.16em;
      }
      > div {
        margin-bottom:calc(#{$marginSides-Double}/2);
        @include bp(mobile) {
          margin-bottom:calc(#{$marginSides-Double});
        }
        @include bp(ipadV) {
          margin-bottom:calc(#{$marginSides-Double});
        }
      }
    }
  }
  .right--wrapper {
    #gmapWrapper {
      width: calc(100% - 50px);
      height: calc(100vh - #{$headerheight} - #{$bordermargin} * 2 - 1px - 50px);
      margin: 25px;
      background-image: url('/img/kontakt/orthuber_map_2.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      @include bp(mobile) {
        margin: 25px 0;
        width: 100%;
      }
      @include bp(ipadV) {
        margin: 25px 0;
        width: 100%;
      }
    }
  }
}
