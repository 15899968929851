.slider {
  position: relative;
  height: 100%;
  width: 100%;
  .refSlideshow {
    position: relative;
    overflow: hidden;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    @include object-fit(cover);
  }
}

.slidePfeil {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: flex-end;
  > div {
    position: absolute;
    bottom: -15%;
    right: 0;
    padding: 0.5rem 0;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    display: flex;
    svg {
      width: 100%;
      height: 100%;
    }
    &:first-of-type {
      right: 5%;
    }
    @include bp(ipadH) {
      bottom: -15%;
      width: 1.5rem;
      height: 1.5rem;
    }
    @include bp(ipadV) {
      bottom: -20%;
      width: 1.5rem;
      height: 1.5rem;
    }
    @include bp(mobile) {
      bottom: -30%;
      width: 2.5rem;
      height: 1.5rem;
    }
  }
}

.pfeil.links {
  transform: rotate(270deg);
  padding: 0.5rem 1rem;
  @include bp(ipadH) {
   padding: 0.5rem 2rem;
  }
  @include bp(ipadV) {
   padding: 0.5rem 2rem;
  }
  @include bp(mobile) {
   padding: 0.5rem 1.5rem;
  }
}
.pfeil.rechts {
  transform: rotate(90deg);
}

.slide{
  width:100%;
  height: 100%;
  position:absolute;
  top: 0;
  left:0;
  transform:translate3d(100%,0,0);
  background:50% 50%/cover no-repeat;
  &.left{
    transform:translate3d(-100%,0,0);
  }
  &.right{
    transform:translate3d(100%,0,0);
  }
  &.show{
    transform:translate3d(0,0,0);
  }
  &.anim{
    transition:transform 2s ease;
  }
}
