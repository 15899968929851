
$widthLeft: 40%;
$widthRight: 60%;
$headerheight: 9vh;
header {
  margin-top: $bordermargin;
  margin-left: $bordermargin;
  margin-right: $bordermargin;
  z-index: 99;
  position: relative;
}
#logo--wrapper {
  min-height: 60px;
  height: $headerheight;
  border-bottom: $bordersize solid $border;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6vh;
  > div {
    padding-top: 0.5em;
    flex:1 auto;
    &:first-of-type {
      width: $widthLeft;
      flex:1 auto;
    }
    &:last-of-type {
      width: $widthRight;
      flex:1 auto;
      span {
        padding-left: 10vw;
      }
    }
  }
}
#homepage {
  #menuButton--wrapper {
    > div {
      &:last-of-type {
        a {
          span {
            transform: rotate(0);
            margin-right: 0;
          }
        }
      }
    }
  }
}
#menuButton--wrapper {
  > div {
    &:last-of-type {
      a {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        span {
          transform: rotate(270deg);
          margin-right: 2rem;
        }
      }
    }
  }
}

#burgerWrapper {
    display: none;
}


@include bp(mobile){
  #burgerWrapper {
    position: relative;
    width: 2rem;
    height:2rem;
    margin: 3rem;
    display: block;
  }

  header {
    margin-top: $bordermargin-sm;
    margin-left: $bordermargin-sm;
    margin-right: $bordermargin-sm;
  }

  #menuButton--wrapper {
    #hrefDesktop {
      display: none;
    }
  }
  #logo--wrapper {
    > div {
      padding-top: 0.5em;
      flex: 0;
      &:first-of-type {
        width: auto;
        flex: 0;
      }
      &:last-of-type {
        width: auto;
        flex: 0;
        span {
          padding-left: 0;
        }
      }
    }
  }
}

@include bp(ipadV){
  #menuButton--wrapper {
    #hrefDesktop {
      display: none;
    }
  }
}

@include bp(ipadH){
  #menuButton--wrapper {
    #hrefMobile {
      display: none;
    }
  }
}

@include bp(desktop){
  #menuButton--wrapper {
    #hrefMobile {
      display: none;
    }
  }
}
