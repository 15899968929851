#homepage {
  #menuTrigger {
    position: absolute;
    top: calc(150vh - #{$bordermargin});
    left: 0;
  }
}

#homepage {
  .mainSection--wrapper {
    @include bp(ipadV){
      min-height: initial;}
    @include bp(mobile){
      min-height: initial;}
    section:first-of-type {
      @include bp(ipadV){
        min-height: initial;}
      @include bp(mobile){
        min-height: initial;}
    }
    ul {
      li {
        a {
          padding: $bordermarginDouble-sm/1.5;
          span {
            transform: rotate(90deg);
          }
        }
        @include bp(ipadH) {
          &.active {
            a {
            letter-spacing: 1vw;

            }
          }
          a {
            padding: $marginSides-Double/2 $marginSides-Double;
            span {
              display: none;
            }
          }
        }
        @include bp(desktop) {
          &.active, &:hover {
            a {
            letter-spacing: 1.4vw;

            }
          }
          a {
            padding: $marginSides-Double/2 $marginSides-Double;
            span {
              display: none;
            }
            &:hover {
              span {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  @include bp(ipadV) {
    .right--wrapper {
      display: none;
    }
  }
  @include bp(mobile) {
    .right--wrapper {
      display: none;
    }
  }
}

#daten--wrapper {
  padding: 1.7vw 3.4vw;
  @include bp(mobile) {
    padding: 6.66667vw;
  }
  a {
    margin-right: 0.5rem;
  }
}

#intro--wrapper {
  height:100vh;
  width:100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  //border-bottom: $bordersize solid $border;
  > div {
    max-width: 70vw;
    font-size: 2.9vw;
    letter-spacing: 0.04em;
    font-family: $fAlight;
    h4 {
      margin: 0.75rem 0;
    }
    @include bp(mobile){
      max-width: 80vw;
      font-size: 20px;
    }
  }
}

#intro--bg {
  height: calc(100vh - #{$headerheight} - #{$bordermargin} - 1px);
  z-index: 1;
  border-bottom: $bordersize solid $border;
}

#intro {
  a {
    border-bottom: $bordersize solid $border;
  }
}
