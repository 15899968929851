#team {
  .menu--container > div {
    &:last-of-type h3 {
      margin-bottom:calc(#{$marginSides-Double} * 0.75);
    }
  }
  table {
    margin-left:calc(#{$marginSides-Double} * 1.5);
    margin-bottom:calc(#{$marginSides-Double} * 1.5);
    .contact_info {
      padding-right: $bordermargin;
    }
    .contact_link a {
      padding: 0;
    }
  }
}
table {
  .contact_info {
    padding-right: $bordermargin;
  }
  .contact_link a {
    padding: 0;
  }
}

#team #listImg0 img {
  @include bp(mobile){
    @include object-fit(cover, left);
  }
  @include bp(ipadV){
    @include object-fit(cover, left);
  }
}
table {
  .contact_info {
    padding-right: $bordermargin;
  }
  .contact_link a {
    padding: 0;
  }
}

#team #listImg0 img {
  @include bp(mobile){
    @include object-fit(cover, left);
  }
  @include bp(ipadV){
    @include object-fit(cover, left);
  }
}

#rightWrapper--desktop {
  padding: 0;
  > div {
    padding: 0 $bordermargin;
    box-sizing: border-box;
    > img {
      padding-bottom: $bordermargin;
      //height: calc(100vh - (#{$bordermarginDouble} * 2) + 1px);
      width: 100%;
      //@include object-fit(cover);

    }
    &:first-of-type {
      margin-top: $bordermargin;
    }
  }
}

#rightWrapper--mobile {
  padding: 0;
  margin: 0;
  #listImg0 img {
    margin: $bordermargin 0;
  }
  div {
    box-sizing: border-box;
      img {
        height: calc(100vh - (#{$bordermarginDouble} * 2) + 1px);
        width: 100%;
        @include object-fit(cover);
      }
    }
  .textDiv--mobile {
    &:first-of-type {
      height: initial;
    }
    position: relative;
    background-color: white;
    h1, h3, h4 {
      padding: $marginSides-Double;
    }
    h1 {
      font-family: $fSMreg;
    }
    h4 {
      margin-top: $marginSides-Double/8;
      margin-bottom: $marginSides-Double/2;
    }
  }
}

@include bp(mobile){
  #rightWrapper--desktop {
    display: none;
  }
  .no--leftWrapper {
    display: none;
  }
  #team table {
    margin-left: 0;
    margin-bottom: 0;
    padding-left: 3.4vw;
    padding-bottom: 3.4vw;
  }
  #team #listImg1>div {
    position: relative;
    top: 0;
    img {
      @include object-fit(cover, right)
    }
  }
  #team #listImg5>div {
    position: relative;
    top: 0;
    left: -50%;
    width: 150%;
  }
}

@include bp(ipadV){
  #rightWrapper--desktop {
    display: none;
  }
  .no--leftWrapper {
    display: none;
  }
  #team table {
    margin-left: 0;
    margin-bottom: 0;
    padding-left: 3.4vw;
    padding-bottom: 3.4vw;
  }

  #team #listImg1>div {
    position: relative;
    top: 0;
    left: -120%;
    width: 220%;
  }
  #team #listImg5>div {
    position: relative;
    top: 0;
    left: -50%;
    width: 150%;
  }
}

@include bp(ipadH){
  #rightWrapper--mobile {
    display: none;
  }
}

@include bp(desktop){
  #rightWrapper--mobile {
    display: none;
  }
}
